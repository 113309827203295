'use client';
import * as React from 'react';
import { useConfigurationPresetSeoItems } from '@/app/configuration-preset/hooks';

interface StructuredDataProps {
  value: Array<Record<string, unknown>> | null;
}

const processStructuredData = (allData: unknown) => {
  const context = 'https://schema.org/';
  const graph: Record<string, unknown>[] = [];

  function collect(data: unknown): void {
    if (!data) {
      return;
    }

    if (Array.isArray(data)) {
      data.forEach((item) => collect(item));
      return;
    }

    if (typeof data === 'object') {
      const originalData = data as Record<string, unknown>;
      const copyData = { ...originalData };

      if (typeof copyData['@context'] === 'string') {
        delete copyData['@context'];
      }

      if (Array.isArray(copyData['@graph'])) {
        const nestedGraph = copyData['@graph'];
        delete copyData['@graph'];
        nestedGraph.forEach((item) => collect(item));
      }

      if (Object.keys(copyData).length > 0) {
        graph.push(copyData);
      }
    }
  }

  collect(allData);

  return {
    '@context': context,
    '@graph': graph,
  };
};

const StructuredData = ({ value }: StructuredDataProps) => {
  const seoItems = useConfigurationPresetSeoItems();
  const scriptId = React.useId();

  const allData = [seoItems?.[0]?.seo?.structuredData, value].filter(Boolean);

  if (!allData.length) {
    return null;
  }

  return (
    <>
      <script id={`sd-cp-${scriptId}`} type="application/ld+json">
        {JSON.stringify(processStructuredData(allData), null, 2)}
      </script>
    </>
  );
};

export default StructuredData;
